require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
require("channels")
require('jquery')
require('packs/flash_messages')
require("packs/cocoon")
require("packs/project")
require("packs/welcome")

import jquery from 'jquery';
window.$ = window.jquery = jquery;

import "bootstrap"
import "@fortawesome/fontawesome-free/js/all"
import "../stylesheets/application"

// import flatpickr from "flatpickr"
// require("flatpickr/dist/flatpickr.css")

// document.addEventListener("turbolinks:load", () => {
//   flatpickr("[data-behavior='flatpickr']", {
//     altInput: true,
//     altFormat: "F j, Y",
//     dateFormat: "Y-m-d"
//   });
// });

// document.addEventListener("turbolinks:load", () => {
//   $('[data-toggle="tooltip"]').tooltip()
//   $('[data-toggle="popover"]').popover()
// });
